<template>
  <div class="text-center mt-5">
    <div class="row">
      <div class="col-lg-12">
        <v-card flat>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-toolbar-title>SAĞLIK RAPORLARIM</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="float-right"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialog = true"
                  depressed
                  color="primary"
                  >EKLE</v-btn
                >
              </template>
              <v-card>
                <v-card-title>Yeni Sağlık Raporu Yükle</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="healthReport.StartDate"
                              label="Başlangıç Tarihi"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              hide-details
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            first-day-of-week="1"
                            v-model="healthReport.StartDate"
                            no-title
                            locale="tr"
                            @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="healthReport.EndDate"
                              label="Bitiş Tarihi"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              hide-details
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            first-day-of-week="1"
                            v-model="healthReport.EndDate"
                            no-title
                            locale="tr"
                            @input="menu3 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          rows="2"
                          label="Açıklama"
                          hide-details
                          v-model="healthReport.Description"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-switch
                          v-model="healthReport.IsCovid19"
                          label="Covid-19"
                          hide-details
                          dense
                        ></v-switch
                      ></v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-switch
                          dense
                          hide-details
                          v-model="healthReport.IsPatient"
                          label="Hasta"
                        ></v-switch
                      ></v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-switch
                          dense
                          v-model="healthReport.IsContactual"
                          hide-details
                          label="Temaslı"
                        ></v-switch
                      ></v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          label="Rapor Yükle"
                          v-show="showFileInput"
                          v-model="attachmentFileInput"
                          truncate-length="19"
                        ></v-file-input>
                        <v-row>
                          <v-text-field
                            label="Yüklü Dosya"
                            v-model="healthReport.AttachmentFile"
                            readonly
                            hide-details
                            v-show="!showFileInput"
                          ></v-text-field>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="
                      healthReport = {}
                      dialog = false
                    "
                  >
                    İptal Et
                  </v-btn>
                  <v-btn :disabled="isDisabled" color="blue darken-1" text @click="save">
                    Kaydet
                  </v-btn>
                </v-card-actions>
                <v-progress-linear
                  :active="loadingAdd"
                  :indeterminate="loadingAdd"
                  color="primary"
                  class="mb-0"
                  height="7"
                ></v-progress-linear>
              </v-card>
            </v-dialog>
            <v-dialog v-model="deleteDialog" persistent width="290px">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                  Bu sağlık raporu kalıcı olarak silinecek. Son kararınız mı?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="deleteHealthReport()"> EVET </v-btn>
                  <v-btn color="blue darken-1" text @click="closeDialog"> HAYIR </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="cancelDialog" persistent width="290px">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                  Bu sağlık raporu iptal edilmesi için yönetici onayına gönderilecek. Son kararınız
                  mı?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancelHealthReport()"> EVET </v-btn>
                  <v-btn color="blue darken-1" text @click="closeDialog"> HAYIR </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-card-text>
            <div
              v-for="(item, index) in healthReports"
              :key="index"
              class="accordion mx-2 my-2"
              role="tablist"
            >
              <b-card no-body>
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block variant="outline-primary" v-b-toggle="'accordion-' + index">
                    <b-form-row class="row--dense">
                      <b-col class="col-8"
                        >Tarih: {{ item.StartDate | moment }} - {{ item.EndDate | moment }}
                      </b-col>
                      <b-col class="col-1"></b-col>
                      <b-col class="col-3">
                        <b-button
                          v-if="item.IsApproved === 0 || item.IsApproved === -1"
                          size="sm"
                          variant="danger"
                          @click="deleteHealthReportDialog(item.Id)"
                          >Sil</b-button
                        >
                        <b-button v-else-if="item.IsCanceled" variant="warning" size="sm" disabled
                          >İptal Bekleniyor</b-button
                        >
                        <b-button
                          v-else
                          size="sm"
                          variant="warning"
                          @click="cancelHealthReportDialog(item.Id)"
                          >İptal Et</b-button
                        >
                      </b-col>
                    </b-form-row>
                  </b-button>
                </b-card-header>

                <b-collapse
                  :visible="index === 0"
                  :id="'accordion-' + index"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>Açıklama: {{ item.Description }}</b-card-text>
                    <b-card-text
                      >Onay Durumu: <span v-if="item.IsApproved === 0"> Onay Bekliyor</span>
                      <span v-else-if="item.IsApproved === 1">Onaylandı</span>
                      <span v-else>Onaylanmadı</span>
                    </b-card-text>
                    <b-form-row>
                      <b-col>
                        <b-form-checkbox class="mx-1" disabled v-model="item.IsCovid19" switch
                          >Covid-19</b-form-checkbox
                        >
                      </b-col>
                      <b-col>
                        <b-form-checkbox class="mx-1" disabled v-model="item.IsContactual" switch
                          >Temaslı</b-form-checkbox
                        >
                      </b-col>
                      <b-col>
                        <b-form-checkbox class="mx-1" disabled v-model="item.IsPatient" switch
                          >Hasta</b-form-checkbox
                        >
                      </b-col>
                    </b-form-row>
                    <b-btn
                      v-show="
                        item.FilePublicUrl != null && item.FilePublicUrl.includes(item.GroupGuid)
                      "
                      @click="downloadFile(item.FilePublicUrl)"
                      >RAPORU İNDİR</b-btn
                    >
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  name: 'saglik-raporlari',
  computed: {
    formattedDate(date) {
      return moment(date).locale('tr').format('DD/MM/YYYY')
    }
  },
  components: {},
  data() {
    return {
      loadingAdd: false,
      healthReports: [],
      dialog: false,
      healthReport: {},
      menu1: false,
      menu3: false,
      attachmentFileInput: null,
      showFileInput: true,
      deleteDialog: false,
      cancelDialog: false,
      healthReportId: 0
    }
  },
  mounted() {
    this.getHealthReports()
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('DD/MM/YYYY') : 'Bitiş Tarihi Yok'
    }
  },
  methods: {
    getHealthReports() {
      ApiService.setHeader()
      ApiService.post('api/HealthReports/Student', {})
        .then((data) => {
          this.healthReports = data.data
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    deleteHealthReportDialog(healthReportId) {
      this.healthReportId = healthReportId
      this.deleteDialog = true
    },
    deleteHealthReport() {
      ApiService.setHeader()
      ApiService.delete('api/HealthReports/' + this.healthReportId)
        .then(() => {
          this.getHealthReports()
          this.deleteDialog = false
          this.healthReportId = 0
          Swal.fire({
            toast: true,
            position: 'bottom',
            icon: 'success',
            title: 'SAĞLIK RAPORU BAŞARIYLA SİLİNDİ',
            showConfirmButton: false,
            timer: 2500
          })
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    cancelHealthReportDialog(healthReportId) {
      this.healthReportId = healthReportId
      this.cancelDialog = true
    },
    cancelHealthReport() {
      ApiService.setHeader()
      ApiService.put('api/HealthReports/Cancel/' + this.healthReportId)
        .then(() => {
          this.getHealthReports()
          this.cancelDialog = false
          this.healthReportId = 0
          Swal.fire({
            toast: true,
            position: 'bottom',
            icon: 'info',
            title: 'YÖNETİCİ ONAYINA GÖNDERİLDİ',
            showConfirmButton: false,
            timer: 2500
          })
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    closeDialog() {
      this.cancelDialog = false
      this.deleteDialog = false
      this.healthReportId = 0
    },
    downloadFile(attachmentFile) {
      window.open(attachmentFile, '_blank')
    },
    async save() {
      if (this.healthReport.StartDate == null) {
        await Swal.fire({
          toast: true,
          position: 'bottom',
          icon: 'error',
          title: 'Başlangıç Tarihi Boş Olamaz',
          showConfirmButton: false,
          timer: 1500
        })
        return
      }
      if (this.attachmentFileInput.size > 1024 * 1024 * 4) {
        await Swal.fire({
          toast: true,
          position: 'bottom',
          icon: 'error',
          title: 'Yüklediğiniz dosya 4 MB üzeri olamaz.',
          showConfirmButton: false,
          timer: 3000
        })
        return
      }
      await ApiService.setHeader()
      try {
        this.isDisabled = true
        this.loadingAdd = true
        var result = await ApiService.put('api/HealthReports/Add', this.healthReport)

        if (this.healthReport && this.attachmentFileInput) {
          var file = this.attachmentFileInput
          let formData = new FormData()
          formData.append('file', file)
          await ApiService.setHeader()
          try {
            await ApiService.postwithconfig(
              'api/healthreports/upload?groupguid=' + result.data,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
            ).then(() => {
              this.healthReport = {}
              this.attachmentFileInput = null
            })
            this.dialog = false
            this.getHealthReports()
          } catch (response) {
            ApiService.showError(response.response)
          }
        } else {
          await Swal.fire({
            toast: true,
            position: 'bottom',
            icon: 'warning',
            title: 'Belge Yüklemediniz',
            showConfirmButton: false,
            timer: 1500
          })
          this.dialog = false
          this.getHealthReports()
        }
      } catch (response) {
        await Swal.fire({
          toast: true,
          position: 'bottom',
          icon: 'error',
          title: response.data.SummaryMessage,
          showConfirmButton: false,
          timer: 1500
        })
      }
      this.loadingAdd = false
      this.isDisabled = false
    }
  }
}
</script>
